import { useBetween } from "use-between";
import { HexColorPicker } from "react-colorful";
import { SharedSketchVariables } from '../Helpers/SketchHelper';
import './CSS/ColorPicker.css';

const ColorPicker = () => {
    const { toolColor, setToolColor, toolColorHistory } = useBetween(SharedSketchVariables);

    return (
        <div id="ColorPickerWrapper">
            <HexColorPicker
                color={toolColor}
                onChange={setToolColor} />

            <div className="colorHistoryPicker">
                {toolColorHistory.map((presetColor) => (
                    <button
                        key={presetColor}
                        className="previousColor"
                        style={{ background: presetColor }}
                        onMouseDown={() => setToolColor(presetColor)}
                    />
                ))}
            </div>
        </div>
    )
}
export default ColorPicker;