import { Button, Grid, Input, Slider, Stack, styled } from '@mui/joy';
import { useBetween } from "use-between";
import { SharedSketchVariables } from '../Helpers/SketchHelper';
import { SharedVideoVariables } from '../Helpers/VideoHelper';

const ReactPlayerControls = () => {
  const { currentVideoUrl, inputVideoUrl, loadVideo, handleVideoUrlChange, handlePlaybackSpeed, volumeLevel, playbackSpeed, playSpeeds, setPlayStatus, updateProgress, playStatus, muted, setSeeking, setVideoProgress, setInputVideoUrl, videoProgress } = useBetween(SharedVideoVariables);
  return (
    <Grid paddingBottom={1}>
      <Stack direction="row"
        spacing={1}
        paddingBottom={1}>
        <Input
          sx={{ width: '100%' }}
          defaultValue={currentVideoUrl}
          onChange={e => setInputVideoUrl(e.target.value)}
          onBlur={e => { setInputVideoUrl(e.target.value); handleVideoUrlChange() }}
        />
        <Button
          variant="solid"
          onClick={loadVideo}
          disabled={inputVideoUrl === currentVideoUrl ? true : false}
        >
          Load Video
        </Button>
      </Stack>
      <Stack direction="row"
        spacing={2}
        sx={{ width: '100%' }}>
        <Button
          onClick={() => setPlayStatus(!playStatus)} >
          {playStatus ? "Pause" : "Play"}
        </Button>
        <Slider
          sx={{
            "--Slider-track-size": "13px",
            width: "100%"
          }}
          defaultValue={0}
          value={videoProgress}
          min={0}
          max={1000}
          valueLabelDisplay="off"
          onChange={(_, value) => {
            setSeeking(true);
            setVideoProgress(value as number);
          }}
        //  onChangeCommitted={(_, value) => handleSeekChange(value)}
        />
        <Slider
          aria-label="Playback Speed"
          value={playbackSpeed}
          onChange={handlePlaybackSpeed}
          step={0.25}
          size='md'
          min={0.25}
          max={1}
          sx={{ width: '30%' }}
          valueLabelDisplay="off"
          marks={playSpeeds}
        />
      </Stack>
    </Grid>
  )
}

export default ReactPlayerControls;