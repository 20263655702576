import { useState } from 'react';
import { Tools } from 'react-sketch2';

export const SharedSketchVariables = () => {

    const [currentTool, setCurrentTool] = useState(Tools.Pencil),
        [toolColor, setToolColor] = useState('#73dbde'),
        [toolColorHistory, setToolColorHistory] = useState(new Array<string>()),
        [toolWidth, setToolWidth] = useState(5),
        toolMapping = [Tools.Select, Tools.Pencil, Tools.Line, Tools.Circle, Tools.Rectangle];


    const changeTool = (toolNumber :number) => {
        setCurrentTool(toolMapping[toolNumber]);
    },
    decreaseToolWidth = () => setToolWidth(toolWidth - 2),
        increaseToolWidth = () => setToolWidth(toolWidth + 2),
        updateToolColorHistory = () => {
            if (toolColorHistory.indexOf(toolColor) === -1)
                setToolColorHistory([toolColor, ...toolColorHistory.slice(0, 8)]);
        };

    return {
        changeTool,
        currentTool,
        decreaseToolWidth,
        increaseToolWidth,
        setCurrentTool,
        setToolColor,
        setToolWidth,
        toolColor,
        toolColorHistory,
        toolMapping,
        toolWidth,
        updateToolColorHistory
    };
};