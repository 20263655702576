import { useState, useCallback } from 'react';

export const SharedVideoVariables = () => {

  const [clearSketchOnLoad, setClearSketchOnLoad] = useState(false),
    [currentVideoUrl, setCurrentVideoUrl] = useState('https://www.youtube.com/watch?v=yfS3OXbrpdU'),
    [inputVideoUrl, setInputVideoUrl] = useState('https://www.youtube.com/watch?v=yfS3OXbrpdU'),
    [muted, setMuted] = useState(false),
    [playStatus, setPlayStatus] = useState(false),
    [playbackSpeed, setPlaybackSpeed] = useState(1),
    [seeking, setSeeking] = useState(false),
    [videoProgress, setVideoProgress] = useState(0),
    [videoUrlHistory, setVideoUrlHistory] = useState(new Array<string>()),
    [volumeLevel, setVolumeLevel] = useState(1);

  const playSpeeds = [
    {
      value: 0.25,
      label: '0.25x',
    },
    {
      value: 0.5,
      label: '0.5x',
    },
    {
      value: 0.75,
      label: '0.75x',
    },
    {
      value: 1,
      label: '1x',
    },
  ];

  const handleVideoUrlChange = () => {
      if (inputVideoUrl !== "" && videoUrlHistory.find((str) => str === inputVideoUrl) === undefined)
        setVideoUrlHistory(videoUrlHistory => [inputVideoUrl, ...videoUrlHistory.slice(0, 9)]);

    },
    handlePlaybackSpeed = (e: Event, speed: number | number[]) => {
      setPlaybackSpeed(speed as number);
    },
    loadVideo = () => {
        console.log(inputVideoUrl);
        setCurrentVideoUrl(inputVideoUrl);
        /*if (clearSketchOnLoad)
          _sketch?.current?.clear();*/
    },
    toggleClearSketchOnLoad = () => {
      setClearSketchOnLoad(!clearSketchOnLoad);
    },
    toggleMuted = () => {
      setMuted(!muted);
    },
    updateProgress = (state: any) => {
      // Only update video progress bar if not actively seeking.
      console.log(state.played);
      if (!seeking)
        setVideoProgress(state.played * 1000);
    };

  return {
    clearSketchOnLoad,
    currentVideoUrl,
    handlePlaybackSpeed,
    handleVideoUrlChange,
    inputVideoUrl,
    loadVideo,
    muted,
    playSpeeds,
    playStatus,
    playbackSpeed,
    seeking,
    setCurrentVideoUrl,
    setInputVideoUrl,
    setPlayStatus,
    setSeeking,
    setVideoProgress,
    setVolumeLevel,
    toggleClearSketchOnLoad,
    toggleMuted,
    updateProgress,
    videoProgress,
    videoUrlHistory,
    volumeLevel,
  };

}