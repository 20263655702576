import { useRef } from "react";
import { useBetween } from "use-between";
import ReactPlayer from 'react-player';
import { SketchField } from 'react-sketch2';
import { SharedVideoVariables } from '../Helpers/VideoHelper';
import { SharedSketchVariables } from '../Helpers/SketchHelper';
import "./CSS/ReactPlayerAndSketch.css"

const ReactPlayerAndSketch = () => {
  const { currentVideoUrl, volumeLevel, playbackSpeed, setPlayStatus, updateProgress, playStatus, muted } = useBetween(SharedVideoVariables);
  const { currentTool, toolColor, toolWidth, updateToolColorHistory } = useBetween(SharedSketchVariables);

  const _sketch = useRef<any>(),
    _player = useRef<ReactPlayer>(null);

  return (
    <div id="ReactPlayerAndSketchWrapper">
      <ReactPlayer
        className='react-player'
        ref={_player}
        playbackRate={playbackSpeed}
        muted={muted}
        controls={false}
        url={currentVideoUrl}
        width='100%'
        height='100%'
        playing={playStatus}
        onPause={() => setPlayStatus(false)}
        onEnded={() => setPlayStatus(false)}
        volume={volumeLevel}
        onProgress={updateProgress}
        onError={e => console.log('onError', e)}
      />
      <SketchField
        width='100%'
        height='100%'
        ref={_sketch}
        undoSteps={500}
        tool={currentTool}
        lineColor={toolColor}
        lineWidth={toolWidth}
        onMouseDown={updateToolColorHistory} />
    </div>
  )
}
export default ReactPlayerAndSketch;