import React, { useState, useEffect, useRef } from 'react';

import { CssVarsProvider, Divider, Grid, Sheet, Stack, styled } from '@mui/joy';

import ReactPlayerAndSketch from './Components/ReactPlayerAndSketch';
import ReactPlayerControls from './Components/ReactPlayerControls';
import ColorPicker from './Components/ColorPicker';

//import './Telestrator.css';

function Telestrator() {

  const Item = styled(Sheet)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
    background: 'lightblue',
  }));

  return (
    <CssVarsProvider>
      <Grid container spacing={0} sx={{ flexGrow: 1 }}>
        <Grid xs="auto">
          <ColorPicker />
          <Stack
            divider={<Divider sx={{ bgcolor: "black" }} />}
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
          >
            <Item>Item 1</Item>
            <Item>Item 2</Item>
            <Item>Item 3</Item>
          </Stack>
        </Grid>
        <Grid xs padding={1}>
          <Stack>
            <ReactPlayerControls />
            <ReactPlayerAndSketch />
          </Stack>
        </Grid>
      </Grid>
    </CssVarsProvider>
  )
}

export default Telestrator;
